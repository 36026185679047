import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["opacity"]
const _hoisted_2 = ["xlink:href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass(_ctx.svgClass),
    "aria-hidden": "true",
    style: _normalizeStyle(_ctx.svgStyles),
    opacity: _ctx.svgOpacity
  }, [
    _createElementVNode("use", { "xlink:href": _ctx.svgName }, null, 8, _hoisted_2)
  ], 14, _hoisted_1))
}