
import { computed, defineComponent, PropType } from "vue";
import { IconName } from "./iconType";

export default defineComponent({
  name: "SvgIcon",
  props: {
    iconName: {
      type: String as PropType<IconName | string>,
      required: true,
    },
    class: {
      type: String,
    },
    opacity: {
      type: Number,
      default: 1,
    },
    size: {
      type: Number,
    },
    color: {
      type: String,
    },
  },
  setup(props) {
    const svgName = computed(() => `#assetsIcon-${props.iconName}`);
    const svgClass = computed(() => ["svg-icon", props.class]);
    const svgOpacity = computed(() => props.opacity);
    const svgStyles = computed(() => {
      return {
        color: props.color,
        fontSize: props.size ? `${props.size}px` : undefined,
      };
    });
    return {
      svgName,
      svgClass,
      svgOpacity,
      svgStyles,
    };
  },
});
