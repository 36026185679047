import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import SvgIcon from "@/components/SvgIcon/Index.vue";
//  加载svg
const requireIcons = (requireContext: __WebpackModuleApi.RequireContext) =>
  requireContext.keys().map(requireContext);
const req = require.context("./components/SvgIcon/icons", true, /\.svg$/);
requireIcons(req);
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
const app = createApp(App);
app.component("SvgIcon", SvgIcon);
app.use(Antd).use(router).mount("#app");
