import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    redirect: "/admin",
  },
  {
    path: "/admin",
    name: "admin",
    component: () => import("../views/rooms/RoomsView.vue"),
  },
  {
    path: "/sdk",
    name: "sdk",
    component: () => import("../views/sdk/SdkView.vue"),
  },
  {
    path: "/cofco",
    name: "cofco",
    component: () => import("../views/cofco/CofcoView.vue"),
  },
  {
    path: "/cdsj",
    name: "cdsj",
    component: () => import("../views/cdsj/CdsjView.vue"),
  },
  {
    path: "/mult",
    name: "mult",
    component: () => import("../views/multiple/MultipleView.vue"),
  },
  {
    path: "/slb",
    name: "slb",
    component: () => import("../views/slb/SlbView.vue"),
  },
  {
    path: "/test",
    name: "test",
    component: () => import("../views/test/TestView.vue"),
  },
  {
    path: "/h5",
    name: "mobile",
    component: () => import("../views/mobile/MobileView.vue"),
  },
  {
    path: "/preview",
    name: "preview",
    component: () => import("../views/preview/PreviewView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
