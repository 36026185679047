<template>
  <router-view />
</template>

<style lang="less">
* {
  padding: 0;
  margin: 0;
}
html,
body {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
#app {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #010101;
  height: 100%;
  width: 100%;
}
</style>
